import { ILogistic } from "../types/logistic.types";

export async function fetchTracker({ params }: { params: any }): Promise<any> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/tracker/${params.id}`,
      { credentials: "include" }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function getTracker(id: string): Promise<any> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/tracker/${id}`,
      { credentials: "include" }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data.tracker;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function fetchTrackersList(query?: string): Promise<any> {
  let queryUrl = query ? query : "";
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/tracker/${queryUrl}`,
      { credentials: "include" }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function fetchUnfinishedTrackersList(
  query?: string
): Promise<any> {
  let queryUrl = query ? query : "";
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/tracker/unfinished-trackers-to-deliver${queryUrl}`,
      { credentials: "include" }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function fetchPayedTrackersList(query?: string): Promise<any> {
  let queryUrl = query ? query : "";
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/tracker/payed-trackers-to-move${queryUrl}`,
      { credentials: "include" }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function loadCart({
  params,
}: {
  params: any;
}): Promise<any | null> {
  console.debug("Load CArt")
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/tracker/${params.trackerId}/new-cart`,
      { credentials: "include" }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    console.debug("DATA", data)
    return data;
  } catch (error: any) {
    console.debug(error)
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}


export async function loadLogistic({
  params,
}: {
  params: any;
}): Promise<any | null> {
  console.debug("Load logistic")
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/tracker/${params.trackerId}/new-logistic`,
      { credentials: "include" }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function loadQuote({
  params,
}: {
  params: any;
}): Promise<any | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/tracker/${params.trackerId}/new-quote`,
      { credentials: "include" }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(data, { status: response.status });
    }
    if (data.quote) return data.quote;
    return data;
  } catch (error: any) {
    throw new Response(error);
  }
}

export async function loadShipping({
  params,
}: {
  params: any;
}): Promise<any | null> {
  try {
    const endpoint =
      params.direction === "outgoing"
        ? "new-outgoing-shipping"
        : "new-incoming-shipping";
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/tracker/${params.trackerId}/${endpoint}`,
      { credentials: "include" }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(data, { status: response.status });
    }
    return data;
  } catch (error: any) {
    throw new Response(error);
  }
}

export async function fetchLogisticFromTrackerId({
  params,
}: {
  params: any;
}): Promise<ILogistic | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/tracker/${params.id}/logistic`,
      { credentials: "include" }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data.logistic;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function updateTrackerState(state: string, trackerId: string) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/tracker/${trackerId}`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        credentials: "include",
        method: "PUT",
        body: JSON.stringify({ state }),
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}
