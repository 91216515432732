import { useQuery } from "react-query";
import LogisticModal from "../../../../components/modals/logisticModal/logisticModal";
import ProductModal from "../../../../components/modals/productModal/ProductModal";
import WorkshopId from "../../../../components/workshopId/workshopId";
import { useModalComponent } from "../../../../context/modalComponent.context";
import { getOperationsListAcronym } from "../../../../translation/operation";
import { IProduct } from "../../../../types/logistic.types";
import { dateDiffInDays } from "../../../../utils/utils";
import style from "./productCard.module.scss";
import { fetchLogisticList } from "../../../../requests/logistic";
import { useState } from "react";
import LoadingSpinner from "../../../../components/loadingSpinner/loadingSpinner";

interface ProductCardProps {
  product: IProduct;
  showOperationAcronym?: boolean;
}

export default function ProductCard({
  product,
  showOperationAcronym = false,
}: ProductCardProps) {
  const { setModalIsOpen, setModalChildren } = useModalComponent();
  const operationAcronym = getOperationsListAcronym(product.operationsList);
  const query = useState<undefined | string>(undefined);

  return (
    <div className={style["product-card"]} onClick={handleOpenModal}>
      <div className={style["product-card-left"]}>
        <WorkshopId
          className={style["workshop-id"]}
          workshopId={product.workshopId}
          size="small"
        />
        <div className={style["brand"]}>{product.brand}</div>
        {showOperationAcronym && (
          <div className={style["operations-acronym-list"]}>
            {operationAcronym.map((opAcro) => {
              return (
                <div className={style["operations-acronym"]}>{opAcro}</div>
              );
            })}
          </div>
        )}
      </div>
      {product.dueDate && (
        <div className={style["due-date"]}>
          {`${new Date(product.dueDate).toLocaleDateString("fr-FR")} `}
          <span>
            {`(${dateDiffInDays(new Date(), new Date(product.dueDate))} jours)`}
          </span>
        </div>
      )}
    </div>
  );

  async function handleOpenModal() {
    if (!product.trackId) {
      return;
    }
    setModalChildren(<LoadingSpinner color="gold" />);
    setModalIsOpen(true);
    fetchLogisticList(`?trackId=${product.trackId}`).then((data) => {
      let logistic = data?.logisticsList.at(0);
      if (!logistic) {
        setModalIsOpen(false);
        return;
      }
      let productIndex = logistic.productsList.findIndex(
        (p) => p.workshopId === product.workshopId
      );
      setModalChildren(
        <LogisticModal
          id={logistic.id}
          requiredActions={false}
          dueDate={product.dueDate}
          productIndex={productIndex >= 0 ? productIndex : 0}
        />
      );
    });
  }
}
