import { Save } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import Widget from "../../../../components/widget/widget";
import { streamFile } from "../../../../requests/file";
import {
  addLogistic,
  deleteFileFromLogistic,
  updateLogistic,
} from "../../../../requests/logistic";
import { loadLogistic } from "../../../../requests/tracker";
import { IOperation } from "../../../../types/accounting.types";
import {
  IContact,
  ILogistic,
  IProduct,
} from "../../../../types/logistic.types";
import { AddImageModal } from "./addImageModal/addImageModal";
import style from "./cartToLogistic.module.scss";
import OperationSection from "./operationSection/operationSection";
import ProductSection from "./productSection/productSection";
import ProductSlider from "./productSlider/productSlider";
import ContactInfoCard from "../../../../components/contactInfoCard/contactInfoCard";
import { TOAST_ERROR_OPTIONS } from "../../../../utils/toast.options";
import { toast } from "react-toastify";
import { useConfirmModal } from "../../../../context/confirmationModalContext";
import ConfirmModalComp from "../../../../components/confirmationModal/ConfirmModalComp";
import ContactForm from "../../../../components/forms/ContactForm/ContactForm";
import Tmp from "./tmp/tmp";

interface TempProduct extends IProduct {
  key?: string;
}

export default function CartToLogistic() {
  const { trackId } = useParams();
  const [logistic, setLogistic] = useState<ILogistic | undefined>(undefined);
  const [validationError, setValidationError] = useState<string[]>([]);
  const [translateOffset, setTranslateOffset] = useState<number>(0);
  const [sliderOffset, setSliderOffset] = useState<number>(0);
  const [steps, setSteps] = useState<number[] | undefined>(undefined); //;
  const [fileList, setFileList] = useState<File[][]>([]);
  const navigate = useNavigate();
  const { setConfirmModalChildren, setConfirmModalIsOpen } = useConfirmModal();
  const WIDGETS_WIDTH = 1008;

  // const { data, refetch } = useQuery(
  //   ["cart-to-logistic", trackId],
  //   () => loadLogistic({ params: { trackerId: trackId } }),
  //   { refetchOnWindowFocus: false, keepPreviousData: true }
  // );

  // useEffect(() => {
  //   if (!data || !data.logistic) {
  //     return;
  //   }
  //   data.logistic.productsList.forEach(
  //     (p: any) => (p.key = Math.floor(Math.random() * Date.now()).toString())
  //   );
  //   setLogistic(data.logistic);
  //   setSteps(
  //     Array.from({ length: data.logistic.productsList.length }, () => 0)
  //   );
  //   setFileList(
  //     Array.from({ length: data.logistic.productsList.length }, () => [])
  //   );
  // }, [data]);

  return (
    <div className={style["cart-to-logistic-container"]}>
      {/* <ContactInfoCard contact={logistic?.contact} trackId={trackId} />
      {logistic && logistic.contact && (
        <Widget className={style["contact-widget-container"]}>
          <ContactForm contact={logistic.contact} setContact={updateContact} />
        </Widget>
      )} */}

      <Tmp
        // logisticData={logistic} onUpdate={() => refetch()}
        fetchingMethod={loadLogistic}
        trackId={trackId as string}
      />
      {/* <ProductSlider
        data={data}
        logistic={logistic}
        setLogistic={setLogistic}
        steps={steps}
        setSteps={setSteps}
        fileList={fileList}
        setFileList={setFileList}
        translateOffset={translateOffset}
        setTranslateOffset={setTranslateOffset}
        sliderOffset={sliderOffset}
        setSliderOffset={setSliderOffset}
      />
      <div className={style["cart-to-logistic-product"]}>
        <div
          className={style["cart-to-logistic-product-container"]}
          style={{ translate: `${translateOffset * -WIDGETS_WIDTH}px` }}
        >
          {logistic &&
            logistic.productsList &&
            steps &&
            logistic.productsList.map((product: TempProduct, index: number) => (
              <React.Fragment key={product.key}>
                <div className={style["widgets-container"]}>
                  <Widget
                    className={style["widget-container"]}
                    selected={steps[index] === 0}
                  >
                    {steps[index] !== 0 && (
                      <div className={style["gray-overlay"]}></div>
                    )}
                    <>
                      <ProductSection
                        contact={logistic.contact}
                        product={product}
                        validationError={validationError}
                        disabled={translateOffset !== index}
                        setValidationError={setValidationError}
                        onChange={(product) =>
                          handleProductChange(product, index)
                        }
                        setSteps={(step) => handleSteps(step, index)}
                      />
                    </>
                  </Widget>
                  <Widget
                    className={style["widget-container"]}
                    selected={steps[index] === 1}
                  >
                    {steps[index] !== 1 && (
                      <div className={style["gray-overlay"]}></div>
                    )}
                    <OperationSection
                      product={product}
                      disabled={translateOffset !== index}
                      onChange={(operationsList) =>
                        handleOperationChange(operationsList, index)
                      }
                      steps={steps[index]}
                      setSteps={(step) => handleSteps(step, index)}
                    />
                  </Widget>
                </div>
                {steps[index] === 2 && (
                  <AddImageModal
                    logistic={logistic}
                    index={index}
                    fileList={fileList[index]}
                    setFileList={(fileList: File[], isWebCam) =>
                      handleFileList(fileList, index, isWebCam)
                    }
                    steps={steps[index]}
                    setSteps={(step) => handleSteps(step, index)}
                    translateOffset={translateOffset}
                    setTranslateOffset={setTranslateOffset}
                    isLast={index === steps.length - 1}
                    handleDeleteImage={handleDeleteImage}
                  />
                )}
              </React.Fragment>
            ))}
        </div>
      </div>
      <div className={style["navigation-container"]}>
        <div></div>
        <div className={style["navigation-container-right"]}>
          <div>
            {allowToSave() && (
              <div
                className={style["save-button"]}
                onClick={() => handleConfirmCreateDocument(logistic)}
              >
                <Save />
                Enregistrer
              </div>
            )}
          </div>
        </div>
      </div> */}
    </div>
  );

  function updateContact(contact: IContact) {
    if (!logistic) {
      return;
    }
    let logisticContact = logistic?.contact ? { ...logistic.contact } : contact;
    logisticContact = { ...logisticContact, ...contact };
    setLogistic({ ...logistic, contact: logisticContact });
  }

  function handleConfirmCreateDocument(logistic?: ILogistic) {
    if (!logistic) {
      toast.error(
        "Un problème est survenu. [logistic document is undefined]",
        TOAST_ERROR_OPTIONS
      );
      return;
    }
    setConfirmModalIsOpen(true);
    setConfirmModalChildren(
      <ConfirmModalComp
        confirmButtonClassName={style["validate-button"]}
        title={"Voulez-vous enregistrer ces articles ?"}
        confirmText="Enregistrer"
        onConfirm={() => handleCreateDocument(logistic)}
      />
    );
  }

  async function handleCreateDocument(logistic: ILogistic) {
    try {
      await submitFiles(`_${logistic.trackId}_logistic.`);
      if (logistic.trackId && logistic.id) {
        await updateLogistic(logistic, true, true);
      } else {
        await addLogistic(logistic, true, true);
      }
      confirmGenerateQuote(logistic.trackId);
    } catch (error: any) {
      toast.error(
        `Un problème est survenu ! [le document logistic n'a pas été créer] Veuillez nous en informer et merci.`,
        TOAST_ERROR_OPTIONS
      );
    }
  }

  function confirmGenerateQuote(trackId: string) {
    setConfirmModalIsOpen(true);
    setConfirmModalChildren(
      <ConfirmModalComp
        confirmButtonClassName={style["validate-button"]}
        title={"Voulez-vous générer un devis ?"}
        confirmText="Oui"
        cancelText="Non"
        onConfirm={() => navigate(`/quick-access/quote-edit/${trackId}`)}
        onCancel={() => navigate(`/quick-access`)}
      />
    );
  }

  async function submitFiles(slug: string) {
    if (!logistic) {
      return;
    }
    try {
      for (const [index, logisticFile] of fileList.entries()) {
        if (logisticFile) {
          const newIncomingPicturesList = [];
          for (let file of logisticFile) {
            const response = await streamFile(slug, file);
            newIncomingPicturesList.push(response.message);
          }
          const newFormValues = { ...logistic };
          if (newFormValues.productsList[index]) {
            if (
              newFormValues.productsList[index].incomingPicturesList ===
              undefined
            ) {
              Object.assign(newFormValues.productsList[index], {
                incomingPicturesList: newIncomingPicturesList,
              });
            } else {
              newFormValues.productsList[index].incomingPicturesList =
                newFormValues.productsList[index].incomingPicturesList?.concat(
                  newIncomingPicturesList
                );
            }
          }
          setLogistic(newFormValues);
        }
      }
    } catch (error) {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
    }
  }

  function handleSteps(step: number, index: number) {
    if (steps) {
      const newSteps = [...steps];
      newSteps[index] = step;
      setSteps(newSteps);
    }
  }

  function handleFileList(_fileList: File[], index: number, isWebCam: boolean) {
    if (fileList) {
      const newFileList = [...fileList];
      if (isWebCam) {
        _fileList.forEach((file: File) => {
          newFileList[index].push(file);
        });
      } else {
        newFileList[index] = _fileList;
      }
      setFileList(newFileList);
    }
  }

  function handleProductChange(product: IProduct, index: number) {
    if (!logistic) {
      return;
    }
    let newLogistic = { ...logistic };
    if (!newLogistic.productsList[index]) {
      return;
    }
    newLogistic.productsList[index] = product;
    setLogistic(newLogistic);
  }

  function handleOperationChange(operationsList: IOperation[], index: number) {
    if (!logistic) {
      return;
    }
    let newLogistic = { ...logistic };
    if (!newLogistic.productsList[index]) {
      return;
    }
    newLogistic.productsList[index].operationsList = operationsList;
    setLogistic(newLogistic);
  }

  // function allowToSave() {
  //   if (data && !data.logistic.productsList.length) {
  //     return false;
  //   }
  //   if (steps) {
  //     for (let step of steps) {
  //       if (step !== 3) return false;
  //     }
  //     return true;
  //   }
  //   return false;
  // }

  async function handleDeleteImage(imageURI: string, index: number) {
    if (
      logistic &&
      window.confirm("Voulez-vous supprimer l'image definitivement")
    ) {
      const newLogistic = { ...logistic };
      if (
        newLogistic.productsList[index] &&
        newLogistic.productsList[index].incomingPicturesList
      ) {
        newLogistic.productsList[index].incomingPicturesList?.splice(index, 1);
      }
      setLogistic(newLogistic);
      await deleteFileFromLogistic(imageURI);
    }
  }
}
