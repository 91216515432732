import { useCallback, useState } from "react";
import { useQuery } from "react-query";
import {
  EstimateReportData,
  getEstimatesReport,
  getOperationsReport,
  getProductsReport,
  ProductReportData,
  ProductReportResponse,
} from "../../requests/reports";
import style from "./index.module.scss";
import clsx from "clsx";
import translate from "../../translation";
import DatePicker from "../../components/datePicker/datePicker";

import { ArrowUpward, ArrowDownward, DragHandle } from "@mui/icons-material";
import { getOperationGroups } from "../../requests/operationPro";
import moment from "moment";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";

const OPERATION_GROUPS_TABLE = [
  "SHOES_MAN",
  "SHOES_WOMAN",
  "SHOES_SNEAKERS",
  "SHOES_CLIMBING",
  "LEATHER_LARGE",
];

const OPERATION_TYPE_TABLE = {
  SHOES_MAN: [
    "SHOES_PATAFER",
    "SHOES_RESSEMELAGE",
    "SHOES_COLLAGE_COUTURE",
    "SHOES_ENTRETIEN",
    "SHOES_TEINTURE",
    "SHOES_MISE_EN_FORME",
    "SHOES_AUTRE",
  ],
  SHOES_WOMAN: [
    "SHOES_PATAFER",
    "SHOES_RESSEMELAGE",
    "SHOES_COLLAGE_COUTURE",
    "SHOES_ENTRETIEN",
    "SHOES_TEINTURE",
    "SHOES_MISE_EN_FORME",
    "SHOES_AUTRE",
  ],
  SHOES_SNEAKERS: [
    "SHOES_PATAFER",
    "SHOES_RESSEMELAGE",
    "SHOES_COLLAGE_COUTURE",
    "SHOES_ENTRETIEN",
    "SHOES_TEINTURE",
    "SHOES_MISE_EN_FORME",
    "SHOES_AUTRE",
  ],
  SHOES_CLIMBING: ["CLIMBING_RESSEMELAGE", "CLIMBING_ENROBAGE"],
  LEATHER_LARGE: [
    "LEATHER_ENTRETIEN",
    "LEATHER_TEINTURE",
    "LEATHER_TRANCHE",
    "LEATHER_DOUBLURE",
    "LEATHER_JONC",
    "LEATHER_COUTURE",
    "LEATHER_ENCHAPE",
    "LEATHER_POIGNEE",
    "LEATHER_BANDOULIERE",
    "LEATHER_FAG",
    "LEATHER_AUTRE",
  ],
};

function EstimatePendingTable({
  dateFrom,
  dateTo,
  compareDateFrom,
  compareDateTo,
}: {
  dateFrom: Date;
  dateTo: Date;
  compareDateFrom?: Date;
  compareDateTo?: Date;
}) {
  const { data: primaryData, isLoading: primaryDataIsLoading  } = useQuery({
    queryKey: [
      "estimate-pending-primary-report",
      {
        dateFrom,
        dateTo,
        eventDescription: "ESTIMATE_PENDING",
        eventService: "ms-booking",
      },
    ],
    queryFn: getEstimatesReport,
  });

  const { data: secondaryData,  isLoading: secondaryDataIsLoading } = useQuery({
    queryKey: [
      "estimate-pending-secondary-report",
      {
        dateFrom: compareDateFrom,
        dateTo: compareDateTo,
        eventDescription: "ESTIMATE_PENDING",
        eventService: "ms-booking",
      },
    ],
    queryFn: getEstimatesReport,
  });

  return (
    <EstimatesTable
    isLoading= {primaryDataIsLoading || secondaryDataIsLoading}
      title={"Demandes d'estimations dans la période (B2C)"}
      primaryData={primaryData?.data || undefined}
      secondaryData={secondaryData?.data || undefined}
    />
  );
}

function EstimateSentTable({
  dateFrom,
  dateTo,
  compareDateFrom,
  compareDateTo,
}: {
  dateFrom: Date;
  dateTo: Date;
  compareDateFrom?: Date;
  compareDateTo?: Date;
}) {
  const { data: primaryData } = useQuery({
    queryKey: [
      "estimate-sent-report",
      {
        dateFrom,
        dateTo,
        eventDescription: "ESTIMATE_SENT",
        eventService: "ms-booking",
      },
    ],
    queryFn: getEstimatesReport,
  });

  const { data: secondaryData } = useQuery({
    queryKey: [
      "compare-estimate-sent-report",
      {
        dateFrom: compareDateFrom,
        dateTo: compareDateTo,
        eventDescription: "ESTIMATE_SENT",
        eventService: "ms-booking",
      },
    ],
    queryFn: getEstimatesReport,
  });

  return (
    <EstimatesTable
      title={"Réponses d'estimations envoyées dans la période (B2C)"}
      primaryData={primaryData?.data || undefined}
      secondaryData={secondaryData?.data || undefined}
    />
  );
}

function OperationsTable({
  dateFrom,
  dateTo,
  compareDateFrom,
  compareDateTo,
  title,
  operationGroup,
}: {
  dateFrom: Date;
  dateTo: Date;
  compareDateFrom?: Date;
  compareDateTo?: Date;
  title: string;
  operationGroup: string;
}) {
  const { data: primaryData, isLoading: primaryDataIsLoading } = useQuery({
    queryKey: [
      `${operationGroup}-operations-primary-report`,
      {
        dateFrom,
        dateTo,
        eventDescription: "BOOKING_PAYMENT_RECEIVED",
        eventService: "ms-accounting",
        operationGroup,
      },
    ],
    queryFn: getOperationsReport,
  });

  const { data: secondaryData,  isLoading: secondaryDataIsLoading } = useQuery({
    queryKey: [
      `${operationGroup}-operations-secondary-report`,
      {
        dateFrom: compareDateFrom,
        dateTo: compareDateTo,
        eventDescription: "BOOKING_PAYMENT_RECEIVED",
        eventService: "ms-accounting",
        operationGroup,
      },
    ],
    queryFn: getOperationsReport,
  });

  return (
    <OperationTypesTable
      isLoading = {primaryDataIsLoading || secondaryDataIsLoading}
      title={title}
      primaryData={primaryData?.data || []}
      secondaryData={secondaryData?.data || []}
      operationGroup={operationGroup}
    />
  );
}

function PayedProductsTable({
  dateFrom,
  dateTo,
  compareDateFrom,
  compareDateTo,
}: {
  dateFrom: Date;
  dateTo: Date;
  compareDateFrom?: Date;
  compareDateTo?: Date;
}) {
  const { data: primaryData, isLoading: primaryDataIsLoading } = useQuery({
    queryKey: [
      "payed-product-primary-report",
      {
        dateFrom,
        dateTo,
        eventDescription: "BOOKING_PAYMENT_RECEIVED",
        eventService: "ms-accounting",
      },
    ],
    queryFn: getProductsReport,
  });

  const { data: secondaryData, isLoading: secondaryDataIsLoading } = useQuery({
    queryKey: [
      "payed-product-secondary-report",
      {
        dateFrom: compareDateFrom,
        dateTo: compareDateTo,
        eventDescription: "BOOKING_PAYMENT_RECEIVED",
        eventService: "ms-accounting",
      },
    ],
    queryFn: getProductsReport,
  });

  return (
    <ProductsTable
    isLoading = {primaryDataIsLoading || secondaryDataIsLoading}
      title={"Articles payés dans la période (B2C)"}
      primaryData={primaryData?.data || []}
      secondaryData={secondaryData?.data || []}
    />
  );
}

function DoneProductsTable({
  dateFrom,
  dateTo,
  compareDateFrom,
  compareDateTo,
}: {
  dateFrom: Date;
  dateTo: Date;
  compareDateFrom?: Date;
  compareDateTo?: Date;
}) {
  const { data: primaryData, isLoading: primaryDataIsLoading } = useQuery({
    queryKey: [
      "done-product-primary-report",
      {
        dateFrom,
        dateTo,
        eventDescription: "LOGISTIC_DONE",
        eventService: "ms-logistic",
      },
    ],
    queryFn: getProductsReport,
  });

  const { data: secondaryData, isLoading: secondaryDataIsLoading  } = useQuery({
    queryKey: [
      "done-product-secondary-report",
      {
        dateFrom: compareDateFrom,
        dateTo: compareDateTo,
        eventDescription: "LOGISTIC_DONE",
        eventService: "ms-logistic",
      },
    ],
    queryFn: getProductsReport,
  });

  return (
    <ProductsTable
    isLoading = {primaryDataIsLoading || secondaryDataIsLoading}
      title={"Articles controllés dans la période (B2C)"}
      primaryData={primaryData?.data || []}
      secondaryData={secondaryData?.data || []}
    />
  );
}

function ComparisonIcon({
  className,
  primaryNumber,
  secondaryNumber,
}: {
  className: string;
  primaryNumber?: number;
  secondaryNumber?: number;
}) {
  if (primaryNumber === undefined || secondaryNumber === undefined) return null;
  if (isNaN(primaryNumber) || isNaN(secondaryNumber)) return null;
  if (primaryNumber > secondaryNumber) {
    return <ArrowUpward className={clsx(className, style["comparison-up"])} />;
  }
  if (primaryNumber < secondaryNumber) {
    return (
      <ArrowDownward className={clsx(className, style["comparison-down"])} />
    );
  }
  return null;
}

function ProductsTable({
  title,
  primaryData,
  secondaryData,
  isLoading,
}: {
  title: string;
  primaryData: ProductReportData[];
  secondaryData?: ProductReportData[];
  isLoading?: boolean;
}) {
  return (
    <div className={style["report-table"]}>
      <div className={clsx(style["product-table-title"])}>{title}</div>
      <div
        className={clsx(style["product-table-grid"], {
          [style["loading"]]: isLoading,
        })}
      >
        <div
          className={clsx(
            style["report-table-item"],
            style["report-table-header"]
          )}
        >
          Catégorie
        </div>
        <div
          className={clsx(
            style["report-table-item"],
            style["product-table-item-count"],
            style["report-table-header"]
          )}
        >
          Total
        </div>
        <div
          className={clsx(
            style["report-table-item"],
            style["report-table-item-price"],
            style["report-table-header"]
          )}
        >
          Prix TTC
        </div>
        {OPERATION_GROUPS_TABLE.map((operationGroup, index) => {
          let primaryItem = primaryData.find(
            (d) => d.itemName === operationGroup
          );
          let secondaryItem = secondaryData?.find(
            (d) => d.itemName === operationGroup
          );

          return (
            <>
              {index > 0 && <hr className={style["product-table-separator"]} />}
              <div className={clsx(style["report-table-item"])}>
                {translate(operationGroup)}
              </div>
              <div
                className={clsx(
                  style["report-table-item"],
                  style["product-table-item-count"]
                )}
              >
                <ComparisonIcon
                  className={style["comparison-icon"]}
                  primaryNumber={primaryItem?.itemCount || 0}
                  secondaryNumber={secondaryItem?.itemCount || 0}
                />
                {primaryItem?.itemCount || 0}
              </div>
              <div
                className={clsx(
                  style["report-table-item"],
                  style["report-table-item-price"]
                )}
              >
                <ComparisonIcon
                  className={style["comparison-icon"]}
                  primaryNumber={primaryItem?.totalPrice || 0}
                  secondaryNumber={secondaryItem?.totalPrice || 0}
                />
                {(
                  Math.round((primaryItem?.totalPrice || 0) * 100) / 100
                ).toFixed(2)}
              </div>
              <>
                <div
                  className={clsx(
                    style["report-table-item"],
                    style["secondary-report-table-item"]
                  )}
                ></div>
                <div
                  className={clsx(
                    style["report-table-item"],
                    style["secondary-report-table-item"],
                    style["product-table-item-count"]
                  )}
                >
                  {secondaryItem?.itemCount || 0}
                </div>
                <div
                  className={clsx(
                    style["report-table-item"],
                    style["secondary-report-table-item"],
                    style["report-table-item-price"]
                  )}
                >
                  {(
                    Math.round((secondaryItem?.totalPrice || 0) * 100) / 100
                  ).toFixed(2)}
                </div>
              </>
            </>
          );
        })}
        <div
          className={clsx(
            style["report-table-item"],
            style["report-table-footer"]
          )}
        ></div>
        <div
          className={clsx(
            style["report-table-item"],
            style["product-table-item-count"],
            style["report-table-footer"]
          )}
        >
          <ComparisonIcon
            className={style["comparison-icon"]}
            primaryNumber={primaryData.reduce(
              (acc, curr) => acc + curr.itemCount,
              0
            ) || 0}
            secondaryNumber={secondaryData?.reduce(
              (acc, curr) => acc + curr.itemCount,
              0
            ) || 0}
          />
          {primaryData?.reduce((acc, curr) => acc + curr.itemCount, 0)}
        </div>
        <div
          className={clsx(
            style["report-table-item"],
            style["report-table-item-price"],
            style["report-table-footer"]
          )}
        >
          <ComparisonIcon
            className={style["comparison-icon"]}
            primaryNumber={primaryData.reduce(
              (acc, curr) => acc + curr.totalPrice,
              0
            ) || 0}
            secondaryNumber={secondaryData?.reduce(
              (acc, curr) => acc + curr.totalPrice,
              0
            ) || 0}
          />
          {(
            Math.round(
              primaryData?.reduce((acc, curr) => acc + curr.totalPrice, 0) * 100
            ) / 100
          ).toFixed(2)}
        </div>
        <div
          className={clsx(
            style["report-table-item"],
            style["secondary-report-table-item"],

            style["report-table-footer"]
          )}
        ></div>
        {secondaryData && (
          <>
            <div
              className={clsx(
                style["report-table-item"],
                style["secondary-report-table-item"],
                style["product-table-item-count"],
                style["report-table-footer"]
              )}
            >
              {secondaryData?.reduce((acc, curr) => acc + curr.itemCount, 0)}
            </div>
            <div
              className={clsx(
                style["report-table-item"],
                style["secondary-report-table-item"],
                style["report-table-item-price"],
                style["report-table-footer"]
              )}
            >
              {(
                Math.round(
                  secondaryData?.reduce(
                    (acc, curr) => acc + curr.totalPrice,
                    0
                  ) * 100
                ) / 100
              ).toFixed(2)}
            </div>
          </>
        )}
      </div>
      <div
        className={clsx(style["loading-overlay"], {
          [style["loading"]]: isLoading,
        })}
      >
        <LoadingSpinner color="gold" />
      </div>
    </div>
  );
}

function OperationTypesTable({
  title,
  primaryData,
  secondaryData,
  operationGroup,
  isLoading,
}: {
  title: string;
  primaryData: ProductReportData[];
  secondaryData?: ProductReportData[];
  operationGroup: string;
  isLoading?: boolean;
}) {
  return (
    <div className={style["report-table"]}>
      <div className={clsx(style["product-table-title"])}>{title}</div>
      <div
        className={clsx(style["product-table-grid"], {
          [style["loading"]]: isLoading,
        })}
      >
        <div
          className={clsx(
            style["report-table-item"],
            style["report-table-header"]
          )}
        >
          Catégorie
        </div>
        <div
          className={clsx(
            style["report-table-item"],
            style["product-table-item-count"],
            style["report-table-header"]
          )}
        >
          Total
        </div>
        <div
          className={clsx(
            style["report-table-item"],
            style["report-table-item-price"],
            style["report-table-header"]
          )}
        >
          Prix TTC
        </div>
        {(
          OPERATION_TYPE_TABLE[
            operationGroup as keyof typeof OPERATION_TYPE_TABLE
          ] || []
        ).map((operationType, index) => {
          let primaryItem = primaryData.find(
            (d) => d.itemName === operationType
          );
          let secondaryItem = secondaryData?.find(
            (d) => d.itemName === operationType
          );

          return (
            <>
              {index > 0 && <hr className={style["product-table-separator"]} />}
              <div className={clsx(style["report-table-item"])}>
                {translate(operationType).split("-").at(-1)?.trim()}
              </div>
              <div
                className={clsx(
                  style["report-table-item"],
                  style["product-table-item-count"]
                )}
              >
                <ComparisonIcon
                  className={style["comparison-icon"]}
                  primaryNumber={primaryItem?.itemCount || 0}
                  secondaryNumber={secondaryItem?.itemCount || 0}
                />
                {primaryItem?.itemCount || 0}
              </div>
              <div
                className={clsx(
                  style["report-table-item"],
                  style["report-table-item-price"]
                )}
              >
                <ComparisonIcon
                  className={style["comparison-icon"]}
                  primaryNumber={primaryItem?.totalPrice || 0}
                  secondaryNumber={secondaryItem?.totalPrice || 0}
                />
                {(
                  Math.round((primaryItem?.totalPrice || 0) * 100) / 100
                ).toFixed(2)}
              </div>
              <>
                <div
                  className={clsx(
                    style["report-table-item"],
                    style["secondary-report-table-item"]
                  )}
                ></div>
                <div
                  className={clsx(
                    style["report-table-item"],
                    style["secondary-report-table-item"],
                    style["product-table-item-count"]
                  )}
                >
                  {secondaryItem?.itemCount || 0}
                </div>
                <div
                  className={clsx(
                    style["report-table-item"],
                    style["secondary-report-table-item"],
                    style["report-table-item-price"]
                  )}
                >
                  {(
                    Math.round((secondaryItem?.totalPrice || 0) * 100) / 100
                  ).toFixed(2)}
                </div>
              </>
            </>
          );
        })}
        <div
          className={clsx(
            style["report-table-item"],
            style["report-table-footer"]
          )}
        ></div>
        <div
          className={clsx(
            style["report-table-item"],
            style["product-table-item-count"],
            style["report-table-footer"]
          )}
        >
          <ComparisonIcon
            className={style["comparison-icon"]}
            primaryNumber={primaryData.reduce(
              (acc, curr) => acc + curr.itemCount,
              0
            ) || 0}
            secondaryNumber={secondaryData?.reduce(
              (acc, curr) => acc + curr.itemCount,
              0
            )  || 0}
          />
          {primaryData?.reduce((acc, curr) => acc + curr.itemCount, 0)}
        </div>
        <div
          className={clsx(
            style["report-table-item"],
            style["report-table-item-price"],
            style["report-table-footer"]
          )}
        >
          <ComparisonIcon
            className={style["comparison-icon"]}
            primaryNumber={primaryData.reduce(
              (acc, curr) => acc + curr.totalPrice,
              0
            )  || 0}
            secondaryNumber={secondaryData?.reduce(
              (acc, curr) => acc + curr.totalPrice,
              0
            )  || 0}
          />
          {(
            Math.round(
              primaryData?.reduce((acc, curr) => acc + curr.totalPrice, 0) * 100
            ) / 100
          ).toFixed(2)}
        </div>
        <div
          className={clsx(
            style["report-table-item"],
            style["secondary-report-table-item"],

            style["report-table-footer"]
          )}
        ></div>
        {secondaryData && (
          <>
            <div
              className={clsx(
                style["report-table-item"],
                style["secondary-report-table-item"],
                style["product-table-item-count"],
                style["report-table-footer"]
              )}
            >
              {secondaryData?.reduce((acc, curr) => acc + curr.itemCount, 0)}
            </div>
            <div
              className={clsx(
                style["report-table-item"],
                style["secondary-report-table-item"],
                style["report-table-item-price"],
                style["report-table-footer"]
              )}
            >
              {(
                Math.round(
                  secondaryData?.reduce(
                    (acc, curr) => acc + curr.totalPrice,
                    0
                  ) * 100
                ) / 100
              ).toFixed(2)}
            </div>
          </>
        )}
      </div>
      <div
        className={clsx(style["loading-overlay"], {
          [style["loading"]]: isLoading,
        })}
      >
        <LoadingSpinner color="gold" />
      </div>
    </div>
  );
}

function EstimatesTable({
  title,
  primaryData,
  secondaryData,
  isLoading,
}: {
  title: string;
  primaryData?: EstimateReportData;
  secondaryData?: EstimateReportData;
  isLoading?: boolean;
}) {
  return (
    <div className={style["report-table"]}>
      <div className={clsx(style["product-table-title"])}>{title}</div>
      <div
        className={clsx(style["estimate-table-grid"], {
          [style["loading"]]: isLoading,
        })}
      >
        <div
          className={clsx(
            style["report-table-item"],
            style["report-table-header"]
          )}
        >
          Catégorie
        </div>
        <div
          className={clsx(
            style["report-table-item"],
            style["report-table-header"]
          )}
        >
          Valeur
        </div>
        <div className={clsx(style["report-table-item"])}>Estimations</div>
        <div className={clsx(style["report-table-item"])}>
          <ComparisonIcon
            primaryNumber={primaryData?.estimateCount || 0}
            secondaryNumber={secondaryData?.estimateCount || 0}
            className={style["comparison-icon"]}
          />
          {primaryData?.estimateCount || 0}
        </div>
        <div className={clsx(style["report-table-item"])}></div>
        <div className={clsx(style["secondary-report-table-item"])}>
          {secondaryData?.estimateCount || 0}
        </div>

        {/* --------------------------------------------------------------------------------------------------------------------------------------- */}
        <hr className={style["estimate-table-separator"]} />
        <div className={clsx(style["report-table-item"])}>Articles</div>
        <div className={clsx(style["report-table-item"])}>
          <ComparisonIcon
            primaryNumber={primaryData?.productCount || 0}
            secondaryNumber={secondaryData?.productCount || 0}
            className={style["comparison-icon"]}
          />
          {primaryData?.productCount || 0}
        </div>
        <div className={clsx(style["report-table-item"])}></div>
        <div className={clsx(style["secondary-report-table-item"])}>
          {secondaryData?.productCount || 0}
        </div>

        {/* --------------------------------------------------------------------------------------------------------------------------------------- */}
        <hr className={style["estimate-table-separator"]} />

        <div className={clsx(style["report-table-item"])}>Maroquineries</div>
        <div className={clsx(style["report-table-item"])}>
          <ComparisonIcon
            primaryNumber={primaryData?.leatherCount || 0}
            secondaryNumber={secondaryData?.leatherCount || 0}
            className={style["comparison-icon"]}
          />
          {primaryData?.leatherCount || 0}
        </div>
        <div className={clsx(style["report-table-item"])}></div>
        <div className={clsx(style["secondary-report-table-item"])}>
          {secondaryData?.leatherCount || 0}
        </div>

        {/* --------------------------------------------------------------------------------------------------------------------------------------- */}
        <hr className={style["estimate-table-separator"]} />

        <div className={clsx(style["report-table-item"])}>Chaussures homme</div>
        <div className={clsx(style["report-table-item"])}>
          <ComparisonIcon
            primaryNumber={primaryData?.manShoesCount || 0}
            secondaryNumber={secondaryData?.manShoesCount || 0}
            className={style["comparison-icon"]}
          />
          {primaryData?.manShoesCount || 0}
        </div>
        <div className={clsx(style["report-table-item"])}></div>
        <div className={clsx(style["secondary-report-table-item"])}>
          {secondaryData?.manShoesCount || 0}
        </div>

        {/* --------------------------------------------------------------------------------------------------------------------------------------- */}
        <hr className={style["estimate-table-separator"]} />

        <div className={clsx(style["report-table-item"])}>Chaussures femme</div>
        <div className={clsx(style["report-table-item"])}>
          <ComparisonIcon
            primaryNumber={primaryData?.womanShoesCount || 0}
            secondaryNumber={secondaryData?.womanShoesCount || 0}
            className={style["comparison-icon"]}
          />
          {primaryData?.womanShoesCount || 0}
        </div>
        <div className={clsx(style["report-table-item"])}></div>
        <div className={clsx(style["secondary-report-table-item"])}>
          {secondaryData?.womanShoesCount || 0}
        </div>

        {/* --------------------------------------------------------------------------------------------------------------------------------------- */}
        <hr className={style["estimate-table-separator"]} />

        <div className={clsx(style["report-table-item"])}>Sneakers</div>
        <div className={clsx(style["report-table-item"])}>
          <ComparisonIcon
            primaryNumber={primaryData?.sneakersShoesCount || 0}
            secondaryNumber={secondaryData?.sneakersShoesCount || 0}
            className={style["comparison-icon"]}
          />
          {primaryData?.sneakersShoesCount || 0}
        </div>
        <div className={clsx(style["report-table-item"])}></div>
        <div className={clsx(style["secondary-report-table-item"])}>
          {secondaryData?.sneakersShoesCount || 0}
        </div>

        {/* --------------------------------------------------------------------------------------------------------------------------------------- */}
        <hr className={style["estimate-table-separator"]} />

        <div className={clsx(style["report-table-item"])}>
          Chaussons d'escalade
        </div>
        <div className={clsx(style["report-table-item"])}>
          <ComparisonIcon
            primaryNumber={primaryData?.climbingShoesCount || 0}
            secondaryNumber={secondaryData?.climbingShoesCount || 0}
            className={style["comparison-icon"]}
          />
          {primaryData?.climbingShoesCount || 0}
        </div>
        <div className={clsx(style["report-table-item"])}></div>
        <div className={clsx(style["secondary-report-table-item"])}>
          {secondaryData?.climbingShoesCount || 0}
        </div>

        {/* --------------------------------------------------------------------------------------------------------------------------------------- */}
        <hr className={style["estimate-table-separator"]} />

        <div className={clsx(style["report-table-item"])}>
          Articles inconnus
        </div>
        <div className={clsx(style["report-table-item"])}>
          <ComparisonIcon
            primaryNumber={primaryData?.unknownCount || 0}
            secondaryNumber={secondaryData?.unknownCount || 0}
            className={style["comparison-icon"]}
          />
          {primaryData?.unknownCount || 0}
        </div>
        <div className={clsx(style["report-table-item"])}></div>
        <div className={clsx(style["secondary-report-table-item"])}>
          {secondaryData?.unknownCount || 0}
        </div>

        {/* --------------------------------------------------------------------------------------------------------------------------------------- */}

        <div
          className={clsx(
            style["report-table-item"],
            style["report-table-footer"]
          )}
        >
          Prix total
        </div>
        <div
          className={clsx(
            style["report-table-item"],
            style["report-table-item-price"],
            style["report-table-footer"]
          )}
        >
          <ComparisonIcon
            primaryNumber={primaryData?.totalPrice || 0}
            secondaryNumber={secondaryData?.totalPrice || 0}
            className={style["comparison-icon"]}
          />
          {(Math.round((primaryData?.totalPrice || 0) * 100) / 100).toFixed(2)}
        </div>
        <div
          className={clsx(
            style["report-table-item"],
            style["report-table-footer"]
          )}
        ></div>
        <div
          className={clsx(
            style["report-table-item"],
            style["secondary-report-table-item"],
            style["report-table-item-price"],
            style["report-table-footer"]
          )}
        >
          {(Math.round((secondaryData?.totalPrice || 0) * 100) / 100).toFixed(
            2
          )}
        </div>

        {/* --------------------------------------------------------------------------------------------------------------------------------------- */}
      </div>
      <div
        className={clsx(style["loading-overlay"], {
          [style["loading"]]: isLoading,
        })}
      >
        <LoadingSpinner color="gold" />
      </div>
    </div>
  );
}

function formatDate(date: string | undefined) {
  return date !== ""
    ? date && new Date(date).toISOString().substring(0, 10)
    : "";
}

function DateRange({
  dateFrom,
  dateTo,
  onChange,
  title,
  disabled,
}: {
  dateFrom: Date;
  dateTo: Date;
  onChange: ({ dateFrom, dateTo }: { dateFrom: Date; dateTo: Date }) => void;
  title?: string;
  disabled?: boolean;
}) {
  return (
    <div className={style["date-range-picker-container"]}>
      <div className={style["date-range-picker-title"]}>{title || ""}</div>
      <div className={style["shipping-date"]}>
        <label>Du</label>
        <input
          disabled={disabled || false}
          name={"dateFrom"}
          type="date"
          lang="fr-FR"
          value={formatDate(dateFrom.toISOString())}
          onChange={handleChange}
          className={style["date-input"]}
        />
      </div>
      <div className={style["shipping-date"]}>
        <label>Au</label>
        <input
          disabled={true}
          name={"dateTo"}
          type="date"
          lang="fr-FR"
          value={formatDate(dateTo.toISOString())}
          onChange={handleChange}
          className={style["date-input"]}
        />
      </div>
    </div>
  );

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    let dateRange = { dateFrom, dateTo };
    dateRange[event.target.name as keyof typeof dateRange] = new Date(
      event.target.value
    );
    onChange && onChange(dateRange);
  }
}

function getFullWeek(referenceDate: Date) {
  let d = moment(referenceDate);
  if (d.endOf("week").isAfter(moment())) {
    d = d.subtract(1, "week");
  }

  return {
    dateFrom: d.startOf("week").toDate(),
    dateTo: d.endOf("week").toDate(),
  };
}

function getFullWeekBefore(referenceDate: Date) {
  let d = moment(referenceDate);
  if (d.endOf("week").isAfter(moment())) {
    d = d.subtract(1, "week");
  }

  d = d.subtract(1, "week");

  return {
    dateFrom: d.startOf("week").toDate(),
    dateTo: d.endOf("week").toDate(),
  };
}

export default function Reports() {
  const [dateRange, setDateRange] = useState(getFullWeek(new Date()));

  const [compareDateRange, setCompareDateRange] = useState(
    getFullWeekBefore(new Date())
  );

  function handleDateChange(dateRange: { dateFrom: Date; dateTo: Date }): void {
    setDateRange(getFullWeek(dateRange.dateFrom));
    setCompareDateRange(getFullWeekBefore(dateRange.dateFrom));
  }

  function handleCompareDateChange(dateRange: {
    dateFrom: Date;
    dateTo: Date;
  }): void {
  }

  return (
    <div className={style["container"]}>
      <div className={style["date-range-container"]}>
        <DateRange
          dateFrom={dateRange.dateFrom}
          dateTo={dateRange.dateTo}
          onChange={handleDateChange}
          title="Période"
        />
        <DateRange
          disabled
          dateFrom={compareDateRange.dateFrom}
          dateTo={compareDateRange.dateTo}
          onChange={handleCompareDateChange}
          title="Période comparative"
        />
      </div>
      <div className={style["tables-container"]}>
        <PayedProductsTable
          dateFrom={dateRange.dateFrom}
          dateTo={dateRange.dateTo}
          compareDateFrom={compareDateRange.dateFrom}
          compareDateTo={compareDateRange.dateTo}
        />
        <DoneProductsTable
          dateFrom={dateRange.dateFrom}
          dateTo={dateRange.dateTo}
          compareDateFrom={compareDateRange.dateFrom}
          compareDateTo={compareDateRange.dateTo}
        />
        <OperationsTable
          dateFrom={dateRange.dateFrom}
          dateTo={dateRange.dateTo}
          compareDateFrom={compareDateRange.dateFrom}
          compareDateTo={compareDateRange.dateTo}
          title={"Chaussures homme payées dans la période (B2C)"}
          operationGroup={"SHOES_MAN"}
        />
        <OperationsTable
          dateFrom={dateRange.dateFrom}
          dateTo={dateRange.dateTo}
          compareDateFrom={compareDateRange.dateFrom}
          compareDateTo={compareDateRange.dateTo}
          title={"Chaussures femme payées dans la période (B2C)"}
          operationGroup={"SHOES_WOMAN"}
        />
        <OperationsTable
          dateFrom={dateRange.dateFrom}
          dateTo={dateRange.dateTo}
          compareDateFrom={compareDateRange.dateFrom}
          compareDateTo={compareDateRange.dateTo}
          title={"Sneakers payées dans la période (B2C)"}
          operationGroup={"SHOES_SNEAKERS"}
        />
        <OperationsTable
          dateFrom={dateRange.dateFrom}
          dateTo={dateRange.dateTo}
          compareDateFrom={compareDateRange.dateFrom}
          compareDateTo={compareDateRange.dateTo}
          title={"Maroquinerie payées dans la période (B2C)"}
          operationGroup={"LEATHER_LARGE"}
        />
        <EstimatePendingTable
          dateFrom={dateRange.dateFrom}
          dateTo={dateRange.dateTo}
          compareDateFrom={compareDateRange.dateFrom}
          compareDateTo={compareDateRange.dateTo}
        />
        <EstimateSentTable
          dateFrom={dateRange.dateFrom}
          dateTo={dateRange.dateTo}
          compareDateFrom={compareDateRange.dateFrom}
          compareDateTo={compareDateRange.dateTo}
        />
      </div>
    </div>
  );
}
