import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import style from "./read.module.scss";
import { Mail, Phone } from "@mui/icons-material";
import EstimationMiniature from "../../../components/miniature/estimationMiniature/estimationMiniature";
import CartMiniature from "../../../components/miniature/cartMiniature/cartMiniature";
import ShippingMiniature from "../../../components/miniature/shippingMiniature/shippingMiniature";
import LogisticMiniature from "../../../components/miniature/logisticMiniature/logisticMiniature";
import QuoteMiniature from "../../../components/miniature/quoteMiniature/quoteMiniature";
import { Link } from "react-router-dom";
import { IEvent } from "../../../types/tracker.types";
import { getTracker, updateTrackerState } from "../../../requests/tracker";
import ContactSearch from "../../../components/contactSearch/contactSearch";
import { linkTracker } from "../../../requests/customer";
import { useQuery } from "react-query";
import { fetchLogistic } from "../../../requests/logistic";
import { useEffect, useState } from "react";
import { useModalComponent } from "../../../context/modalComponent.context";
import PaymentModal from "../../../components/modals/paymentModal/paymentModal";
import { ProtectedComponent } from "../../../components/protectedComponent/protectedComponent";
import State from "../../../components/State/State";
import { TRACKING_STATE } from "../../../utils/tracking.init";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "../../../utils/toast.options";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner";
import Button from "../../../components/Button/Button";
import { useRefetchOnModalClose } from "../../../hooks/useRefetchOnModalClose";

export default function Read() {
  const { id } = useParams();
  const { data, isFetching, refetch } = useQuery(
    ["tracker", id],
    () => getTracker(id || ""),
    { enabled: Boolean(id) }
  );
  const { data: logisticDocument, refetch: logisticRefetch } = useQuery(
    ["overview-logistic-document", data],
    () => fetchLogistic({ params: { id: data.logistic.documentId || "" } }),
    { refetchOnWindowFocus: false }
  );
  const { setModalIsOpen, setModalChildren } = useModalComponent();

  const [logisticRequiredAction, setLogisticRequiredAction] =
    useState<boolean>(false);
  const [quoteRequiredAction, setQuoteRequiredAction] =
    useState<boolean>(false);
  useRefetchOnModalClose(refetch);
  const navigate = useNavigate();

  useEffect(() => {
    setLogisticRequiredAction(logisticDocumentNeedRequiredAction());
  }, [logisticDocument]);

  useEffect(() => {
    setQuoteRequiredAction(quoteDocumentNeedRequiredAction());
  }, [data]);

  if (!data) {
    return <LoadingSpinner color="gold" />;
  }

  return (
    <div className={style["overview-container"]}>
      {data && (
        <>
          <div className={style["overview-container-top"]}>
            <div className={style["contact-info"]}>
              {data.contact ? (
                <>
                  <div className={style["contact-fullname"]}>
                    {data.contact.displayname}
                  </div>
                  <div className={style["contact-coordinate"]}>
                    <Link
                      className={style["mail"]}
                      to={`mailto:${data.contact.email}`}
                    >
                      <Mail className={style["contact-icon"]} />
                      {data.contact.email}
                    </Link>
                    {/* <div className={style["phone"]}> */}
                    <Link
                      className={style["phone"]}
                      to={`tel:${data.contact.phone}`}
                    >
                      <Phone className={style["contact-icon"]} />
                      {data.contact.phone}
                    </Link>
                    {/* </div> */}
                  </div>
                </>
              ) : (
                <ContactSearch
                  onSelect={async (customer) => {
                    if (customer.id) {
                      await linkTracker(customer.id, data.id);
                      // navigate(0);
                    }
                  }}
                />
              )}
            </div>
            <div>
              {/* <StatusItem data={data} isGeneral /> */}
              <State
                state={data.state}
                stateList={TRACKING_STATE}
                handleSetState={handleChangeTrackerState}
                isTracker={true}
                isLoading={isFetching}
              />
              {!data.payment && (
                <ProtectedComponent roles={["ROLE_ADMIN"]}>
                  <Button
                    className={style["payment-button"]}
                    onClick={openPaymentModal}
                  >
                    Forcer un paiement
                  </Button>
                </ProtectedComponent>
              )}
            </div>
          </div>
          <hr className={style["separator"]} />
          <div className={style["miniature-list-container"]}>
            <div className={style["miniature-list"]}>
              <EstimationMiniature data={data.estimate} />
              <CartMiniature data={data.cart} trackId={data.id} />
              <ShippingMiniature
                trackerId={data.id}
                isCollect={true}
                data={data.incomingShipping}
                contact={data.contact}
              />
              <LogisticMiniature
                refetch={logisticRefetch}
                trackerId={data.id}
                data={data.logistic}
                requiredActions={logisticRequiredAction}
                dueDate={data?.outgoingShipping?.period?.start}
              />
              <QuoteMiniature
                trackerId={data.id}
                quote={data.quote}
                invoice={data.invoice}
                requiredActions={quoteRequiredAction}
              />
              <ShippingMiniature
                trackerId={data.id}
                isCollect={false}
                data={data.outgoingShipping}
                contact={data.contact}
              />
            </div>
          </div>
          <div className={style["history-list"]}>
            <div className={style["header"]}>
              <div className={style["header-element-container"]}>Date</div>
              <div className={style["header-element-container"]}>Document</div>
              <div className={style["header-element-container"]}>Catégorie</div>
              <div className={style["header-element-container"]}>Message</div>
              <div className={style["header-element-container"]}>Source</div>
            </div>
            {data?.eventsList &&
              data.eventsList.map((event: IEvent, index: number) => {
                return (
                  <div className={style["item"]} key={index}>
                    <div className={style["item-element"]}>
                      {new Date(event.date).toLocaleString("fr-FR")}
                    </div>
                    <div className={style["item-element"]}>
                      {event.documentName}
                    </div>
                    <div className={style["item-element"]}>
                      {event.category}
                    </div>
                    <div className={style["item-element"]}>
                      {event.description}
                    </div>
                    <div className={style["item-element"]}>{event.service}</div>
                  </div>
                );
              })}
          </div>
        </>
      )}
    </div>
  );

  async function handleChangeTrackerState(state: string) {
    if (!data || !data.id) {
      return;
    }
    try {
      await updateTrackerState(state, data.id);
      refetch();
      toast.success(
        "L'état à était mis à jour avec succès.",
        TOAST_SUCCESS_OPTIONS
      );
    } catch (error) {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
    }
  }

  function openPaymentModal() {
    if (!data) {
      return;
    }
    setModalIsOpen(true);
    setModalChildren(<PaymentModal tracker={data} />);
    // setHideSide(true);
  }

  function logisticDocumentNeedRequiredAction() {
    if (
      data?.quote?.state === "QUOTE_VALIDATED" &&
      (data?.logistic?.state === "LOGISTIC_QUOTE_SENT" ||
        data?.logistic?.state === "LOGISTIC_INCOMING")
    ) {
      return true;
    }
    if (
      logisticDocument?.crossSell &&
      logisticDocument?.crossSell.crossSellItems &&
      logisticDocument?.crossSell.crossSellItems.length > 0
    ) {
      if (!logisticDocument.crossSell.workshopId) {
        return true;
      }
    }
    return false;
  }

  function quoteDocumentNeedRequiredAction() {
    if (data?.payment && !data.invoice) {
      return true;
    }
    return false;
  }
}
