import { Link } from "react-router-dom";
import style from "./index.module.scss";

export default function DevTools() {
  return (
    <div className={style["dev-tools"]}>
      <div className={style["dev-tools-grid"]}>
        <ToolButton title="CREATE SHIPPING PRO" to={"create-shipping-pro"} />
        <ToolButton title="CREATE SHIPPING b2c" to={"create-shipping-pro"} />
        <ToolButton
          title="CREATE CHRONOPOST SHIPPING PRO"
          to={"create-chronopost"}
        />
        <ToolButton title="MODAL_TEST" to={"modal-test"} />
        <ToolButton title="IMAGE_CONVERSION" to={"image-conversion"} />
      </div>
    </div>
  );
}

interface ToolButtonProps {
  title: string;
  to: string;
}

function ToolButton({ title, to }: ToolButtonProps) {
  return (
    <Link className={style["tool-button"]} to={to}>
      {title}
    </Link>
  );
}
